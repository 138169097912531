@import '../../../styles/main.scss';

.Nav {
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;

  @include for-mobile-only {
    flex-direction: column;
    padding: 0;
    margin-bottom: 50px;
  }

  @include for-tablet-portrait-up {
    margin-right: 30px;
    flex-grow: 1;
  }
}

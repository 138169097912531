@keyframes swing-in {
  0% {
    transform: rotateX(70deg);
    transform-origin: top;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

$swing-in: swing-in 0.3s cubic-bezier(.175,.885,.32,1.275) both;

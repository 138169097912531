@import '../../../styles/main.scss';

.Hero {
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100svh - $header-height-mobile);
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include for-tablet-portrait-up {
    min-height: calc(100vh - $header-height-standard);
    min-height: calc(100svh - $header-height-standard);
    flex-direction: column;
  }

  @include for-desktop-up {
    flex-direction: row;
  }
}

.ImagePortrait {
  display: none;

  @include for-desktop-up {
    width: 25%;
    margin-right: 70px;
    display: inline;
  }
}

.ImageRound {
  width: 55%;

  @include for-tablet-portrait-up {
    margin-bottom: 30px;
    width: 40%;
  }

  @include for-desktop-up {
    display: none;
  }
}

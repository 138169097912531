@import '../../../styles/main.scss';

.Contact {
  color: $color-white;
  font-size: $font-size-coati;
  margin: 0 10px;

  @include for-tablet-portrait-up {
    font-size: $font-size-eagle;
    margin: 0 15px;
  }

  @include for-tablet-landscape-up {
    font-size: $font-size-coati;
  }

  @include for-desktop-up {
    font-size: $font-size-raccoon;
  }

  &:hover {
    color: $color-transparent-85;
  }
}

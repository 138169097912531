@import '../../../styles/main.scss';

.Arrow {
  width: 30px;
  animation: bounce 2s ease infinite;
	position: absolute;
	bottom: 15px;

	@include for-tablet-portrait-up {
 		width: 40px;
		bottom: 30px;
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
	40% { transform: translateY(-10px); }
	60% { transform: translateY(-5px); }
}

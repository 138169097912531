@import '../../../styles/main.scss';

.FolderPopupContent {
  color: $color-secondary;
  padding: 32px;
  width: 100%;
  height: 100%;
}

.ScrollableContainer {
  height: inherit;
  overflow-y: auto;
}

.Image {
  width: 100%;
}

@import '../../../styles/main.scss';

.Specializations {
  background-color: $color-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-mobile-only {
    min-height: calc(100vh - $header-height-mobile);
    min-height: calc(100svh - $header-height-mobile);
    padding: 48px 0;
  }

  @include for-tablet-portrait-up {
    padding: 80px 0;
  }
}

.Text {
  text-align: center;
  font-size: $font-size-mouse;

  @include for-tablet-portrait-up {
    font-size: $font-size-cat;
  }
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  flex-direction: column;

  @include for-tablet-portrait-up {
    flex-direction: row;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
  }
}

@import '../../../styles/main.scss';

@mixin active {
  color: $color-primary;

  @include for-mobile-only {
    border: none;
    background-color: $color-secondary;
    border-radius: 50px;
  }
}

.NavItem {
  font-size: $font-size-goldfish;
  margin: 0 15px;

  @include for-mobile-only {
    font-size: $font-size-cat;
    margin-bottom: 30px;
  }

  @include for-desktop-large-up {
    font-size: $font-size-cat;
  }

  a {
    display: inline-block;
    color: $color-white;
    padding: 0 15px 5px;
    text-decoration: none;
    transition: 0.3s;

    @include for-mobile-only {
      padding: 10px 30px;
    }

    &:hover {
      @include active;
    }
  }

  &.isActive {
    a {
      @include active;
      border-bottom: 1px solid $color-primary;
    }
  }
}

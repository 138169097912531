@mixin for-mobile-only {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin for-desktop-large-up {
  @media (min-width: 1920px) {
    @content;
  }
}

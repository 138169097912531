@import '../../../styles/main.scss';

.Button {
  background-color: $color-black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  height: 50px;
  font-size: $font-size-goldfish;
  padding: 10px 20px;
  border: 1px solid $color-transparent-30;
  text-align: center;
  cursor: pointer;

  @include for-mobile-only {
    width: 250px;
  }
}

.Icon {
  color: $color-white;
  margin-left: 10px;

  @include for-tablet-portrait-up {
    transition: 0.15s;

    &.rotate {
      transform: rotate(-180deg);
    }
  }
}

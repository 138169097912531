$color-background: rgb(17, 35, 46);

$color-primary: rgb(255, 244, 143);
$color-secondary: rgb(22, 44, 57);
$color-tertiary: rgb(27, 53, 68);

$color-white: rgb(255, 255, 255);
$color-black: rgb(10, 10, 10);

$color-transparent-10: rgba(255, 255, 255, 0.1);
$color-transparent-15: rgba(255, 255, 255, 0.15);
$color-transparent-30: rgba(255, 255, 255, 0.3);
$color-transparent-50: rgba(255, 255, 255, 0.5);
$color-transparent-85: rgba(255, 255, 255, 0.85);

@import '../../../styles/main.scss';

.DropdownButton {
  width: 35%;
  display: flex;
  justify-content: center;

  @include for-mobile-only {
    margin-bottom: 30px;
    width: 100%;
  }

  @include for-tablet-portrait-up {
    position: absolute;
    top: 100px;
    right: 100px;
    margin-top: 5px; // (Heading / 2) - (DropdownButton / 2)
    flex-direction: column;
  }

  @include for-desktop-up {
    top: 110px;
    right: 110px;
    margin-top: 11px;
  }

  @include for-desktop-large-up {
    top: 130px;
    right: 130px;
    margin-top: 23px;
  }

  // Right-align button
  > * {
    @include for-tablet-portrait-up {
      margin-left: auto;
    }
  }
}

@import '../../../styles/main.scss';

.Checkbox {
  appearance: none;
  background-color: $color-white;
  margin: 0;
  font: inherit;
  width: 23px;
  height: 23px;
  display: grid;
  place-content: center;

  &:before {
    width: 0.65em;
    height: 0.65em;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
  }

  &:checked {
    background-color: $color-primary;

    &:before {
      transform: scale(1);
    }
  }

  &:before {
    content: "";
    transform: scale(0);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); // Checkmark
  }

  &.indeterminate {
    background-color: $color-primary;

    &:before {
      content: "";
      transform: scale(1);
      clip-path: polygon(0 37%, 100% 37%, 100% 63%, 0 63%); // Horizontal line
    }
  }
}

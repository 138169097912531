@import '../../../styles/main.scss';

@keyframes slide {
	0% {
		left: 50px;
	}

	100% {
		left: 0;
	}
}

.SpeechBubble {
  display: flex;
  border-radius: 60px;
  background-color: $color-white;
  color: $color-black;
  position: relative;
  margin: 0;
  flex-direction: column;
  padding: 30px;

  @include for-mobile-only {
    height: 50vh;
  }

  @include for-tablet-portrait-up {
    margin-bottom: 50px;
    flex-direction: row;
    padding: 20px;
    width: 500px;
    padding: 40px;
    border-radius: 120px;
    animation: slide 0.3s;
  }

  @include for-desktop-up {
    width: 600px;
    padding: 50px;
  }

  @include for-desktop-large-up {
    width: 900px;
    padding: 70px;
    border-radius: 170px;
  }

  &:before {
    @include for-tablet-portrait-up {
      content: "";
      position: absolute;
      width: 15px;
      height: 70px;
      background-color: $color-white;
      z-index: -1;
      bottom: 10px;
      left: 43%;
      transform: skew(-50deg, 80deg);
    }

    @include for-tablet-landscape-up {
      left: 2%;
      top: 29%;
      width: 50px;
      transform: skew(-55deg, 22deg);
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 25px;
  height: inherit;

  @include for-tablet-portrait-up {
    padding: 20px;
    height: 250px;
  }

  @include for-tablet-landscape-up {
    height: 230px;
  }

  @include for-desktop-large-up {
    height: 300px;
  }
}

.Text {
  font-size: $font-size-mouse;

  @include for-tablet-portrait-up {
    font-size: $font-size-mouse;
    margin-bottom: 50px;
  }

  @include for-desktop-up {
    font-size: $font-size-mouse;
    margin-bottom: 0;
  }

  @include for-desktop-large-up {
    font-size: $font-size-coati;
  }
}

.Image {
  height: 35px;

  @include for-tablet-portrait-up {
    margin-right: 20px;
  }

  @include for-tablet-landscape-up {
    height: 50px;
  }

  @include for-desktop-up {
    height: 60px;
  }

  @include for-desktop-large-up {
    height: 80px;
  }
}

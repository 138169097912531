@import '../../../styles/main.scss';

$crossHeight: 36px;
$crossPaddingVertical: 40px;
$space: $crossHeight + $crossPaddingVertical + $crossPaddingVertical;

.FolderPopup {
  position: relative;

  @include for-mobile-only {
    position: absolute;
    height: calc(100vh - $space - $crossPaddingVertical);
    top: $space;
    max-width: calc(100% - 20px);
  }

  @include for-tablet-portrait-up {
    max-height: 95%;
    max-width: 90%;
  }

  @include for-tablet-landscape-up {
    max-width: 900px;
  }
}

.Frame {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
}

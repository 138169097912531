@import '../../../styles/main.scss';

.DropdownList {
  @include for-mobile-only {
    padding: 0 25px 25px;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }
}

@import '../../../styles/main.scss';

.FolderPopupButton {
  text-decoration: none;
}

.Icon {
  font-size: $font-size-goldfish;
  padding-left: 6px;
}

@import '../../../styles/main.scss';

.DropdownListItem {
  font-size: $font-size-goldfish;
  list-style: none;
  padding: 20px 10px;

  @include for-tablet-portrait-up {
    padding: 15px 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-transparent-15;
  }

  &:first-child {
    border-bottom: 1px solid $color-transparent-50;
    font-weight: bold;

    label {
      font-size: $font-size-mouse;
    }
  }
}

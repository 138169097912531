@import '../../../styles/main.scss';

.Folders {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-desktop-up {
    grid-template-columns: repeat(4, 1fr);
  }
}

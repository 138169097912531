@import '../../../styles/main.scss';

.CircleBase {
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.CircleSolid {
  @extend .CircleBase;
  width: $font-size-giraffe;
  height: $font-size-giraffe;
  background-color: $color-tertiary;
  bottom: 10%;
  left: 12%;
  display: none;
  animation-name: bounceUpDown;

  @include for-desktop-up {
    display: inline;
  }

  @include for-desktop-large-up {
    bottom: 12%;
    left: 22%;
  }
}

.CircleOutlined {
  @extend .CircleBase;
  width: $font-size-megalodon;
  height: $font-size-megalodon;
  border: 2px solid $color-tertiary;
  background-color: transparent;
  top: 15%;
  right: 10%;
  display: none;
  animation-name: bounceDownUp;

  @include for-desktop-up {
    display: inline;
  }
}

@keyframes bounceUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

@keyframes bounceDownUp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
}

@import '../../../styles/main.scss';

.Language {
  width: 40px;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include for-mobile-only {
    width: 50px;
    border-radius: 15px;
  }

  @include for-desktop-large-up {
    width: 50px;
  }
}

$font-size-ant: 0.938rem;       // 15px
$font-size-goldfish: 1.25rem;   // 20px
$font-size-mouse: 1.563rem;     // 25px
$font-size-cat: 1.875rem;       // 30px
$font-size-coati: 2.188rem;     // 35px
$font-size-raccoon: 2.5rem;     // 40px
$font-size-eagle: 3.125rem;     // 50px
$font-size-whale: 3.75rem;      // 60px
$font-size-elephant: 5rem;      // 80px
$font-size-giraffe: 6.25rem;    // 100px
$font-size-dinosaur: 9.375rem;  // 150px
$font-size-megalodon: 18.75rem; // 300px

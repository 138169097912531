@import '../../styles/main.scss';

.container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  max-width: 400px;

  @include for-mobile-only {
    position: relative;
  }

  @include for-tablet-portrait-up {
    max-width: none;
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
    align-items: flex-end;
  }
}

.Image {
  display: none;

  @include for-tablet-portrait-up {
    width: 180px;
    height: 270px;
    margin-right: 60px;
    display: inline;
  }

  @include for-desktop-up {
    width: 200px;
    height: 300px;
  }

  @include for-desktop-large-up {
    width: 300px;
    height: 420px;
  }
}

@import '../../../styles/main.scss';

.Chevrons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @include for-tablet-portrait-up {
    flex-direction: column;
  }
}

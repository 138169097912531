@import '../../../styles/main.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(17, 35, 46, 0.85);
  z-index: 2;
}

.cross {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 36px;
  color: $color-white;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.95);
    color: $color-primary;
  }
}

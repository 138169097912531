@import '../../../styles/main.scss';

.SpecializationItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 40px;

  @include for-tablet-portrait-up {
    flex-direction: column;
    justify-content: center;
    gap: 0;
    width: 15%;
    background-color: $color-secondary;
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-bottom: 50px;

    @include for-tablet-portrait-up {
      margin-bottom: 0;
    }
  }
}

.Image {
  width: 40px;

  @include for-tablet-portrait-up {
    margin-bottom: 16px;
  }
}

.Text {
  font-size: $font-size-goldfish;

  @include for-tablet-portrait-up {
    text-align: center;
  }
}

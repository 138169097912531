@import '../../../styles/main.scss';

.Folder {
  color: $color-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease;
  overflow: visible;
  padding: 16px;
  aspect-ratio: 1;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
}

.Background {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.Image {
  width: 100%;
}

.Description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px 0;
}

h2.Heading {
  font-family: 'Grand Hotel';
  font-size: clamp($font-size-cat, 7.5vw, $font-size-eagle);

  @include for-tablet-portrait-up {
    font-size: clamp($font-size-cat, 4vw, $font-size-raccoon);
  }

  @include for-desktop-up {
    font-size: clamp($font-size-mouse, 2vw, 2vw);
  }
}

.Icon {
  color: $color-secondary;
  font-size: $font-size-cat;
  transition: transform 0.3s ease;

  @include for-mobile-only {
    font-size: $font-size-cat;
  }

  &:hover {
    transform: scale(0.95);
  }}

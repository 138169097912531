@import '../../../styles/main.scss';

.SkillImage {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  width: 110px;
  height: 110px;
  margin-bottom: 15px;
  border-radius: 50%;
  padding: 20%;

  @include for-desktop-large-up {
    width: 140px;
    height: 140px;
  }
}

.image {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.icon {
  background-image: url("../../../images/brain-solid.svg");
  opacity: 0.1;
  width: 85%;
  height: 85%;
}

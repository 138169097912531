@import '../../../styles/main.scss';

.Dropdown {
  background-color: $color-black;
  animation: $swing-in;

  @include for-mobile-only {
    height: 100%;
    height: 100svh;
    width: 100vw;
    padding-top: 100px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: $color-secondary;
  }

  @include for-tablet-portrait-up {
    border: 1px solid $color-transparent-15;
  }
}

.cross {
  background-color: $color-secondary;
  width: 100%;
  padding: 25px 30px 25px 25px;
  position: fixed;
  top: 0;
  text-align: right;
  font-size: $font-size-cat;

  @include for-tablet-portrait-up {
    display: none;
  }
}

@import '../../../styles/main.scss';

.CheckboxGroup {
  font-size: $font-size-goldfish;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-tablet-portrait-up {
    font-size: $font-size-goldfish;
  }
}

.checkboxWrapper {
  padding-left: 20px;
}

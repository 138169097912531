@import '../../../styles/main.scss';

.HeroInfo {
  text-align: center;
}

h1.Heading {
  margin-bottom: 8px;
  font-family: "Grand Hotel";
  font-weight: 100;
  font-size: 100px;

  @include for-mobile-only {
    font-size: $font-size-whale;
    margin-top: $font-size-cat;
  }

  @include for-tablet-portrait-up {
    font-size: $font-size-giraffe;
  }

  @include for-desktop-up {
    font-size: $font-size-dinosaur;
  }
}

.Text {
  font-size: $font-size-goldfish;

  @include for-tablet-landscape-up {
    font-size: $font-size-mouse;
  }

  @include for-desktop-up {
    font-size: $font-size-cat;
  }

  @include for-desktop-large-up {
    font-size: $font-size-eagle;
  }
}

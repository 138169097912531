@import '../../../styles/main.scss';

.Skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;

  @include for-desktop-large-up {
    width: 150px;
  }
}

.name {
  text-align: center;
  word-break: break-word;

  @include for-tablet-landscape-up {
    font-size: $font-size-goldfish;
  }

  @include for-desktop-large-up {
    font-size: $font-size-mouse;
  }
}

.description {
  font-style: italic;
}

@import '../../../styles/main.scss';

h2.Title {
  font-family: 'Grand Hotel';
  font-size: $font-size-raccoon;
  padding: 20px 8px 8px 0;
}

h3.Subtitle {
  font-size: $font-size-goldfish;
  font-weight: bold;
  padding-bottom: 4px;
}

.Text {
  padding-bottom: 16px;
  font-size: $font-size-goldfish;
}

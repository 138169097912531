@import '../../../styles/main.scss';

.Main {
  background-image: url(../../../images/bg-grain.png);
  background-repeat: repeat;
  height: calc(100% - $header-height-standard);
  min-height: calc(100vh - $header-height-standard);
  margin-top: $header-height-standard;
  position: relative;

  @include for-mobile-only {
    height: calc(100% - $header-height-mobile);
    min-height: calc(100vh - $header-height-mobile);
    margin-top: $header-height-mobile;
  }

  &.padded {
    padding: 64px 25px;

    @include for-tablet-portrait-up {
      padding: 100px 70px;
    }

    @include for-desktop-up {
      padding: 110px 80px;
    }

    @include for-desktop-large-up {
      padding: 130px 100px;
    }
  }

  &.vCentered {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include for-tablet-landscape-up {
      align-items: normal;
    }
  }
}

.Heading {
  text-align: center;

  @include for-tablet-portrait-up {
    text-align: left;
  }
}

@import '../../../styles/main.scss';

.Heading {
  font-size: $font-size-whale;
}

h1.Heading {
  font-size: $font-size-raccoon;
  margin-bottom: 40px;

  @include for-tablet-portrait-up {
    font-size: $font-size-eagle;
  }

  @include for-desktop-up {
    font-size: $font-size-whale;
  }

  @include for-desktop-large-up {
    font-size: $font-size-elephant;
  }
}

h2.Heading {
  font-size: $font-size-goldfish;

  @include for-tablet-landscape-up {
    font-size: $font-size-mouse;
  }

  @include for-desktop-up {
    font-size: $font-size-cat;
  }

  @include for-desktop-large-up {
    font-size: $font-size-raccoon;
  }
}

h3.Heading {
  font-size: $font-size-ant;
  font-weight: normal;
}

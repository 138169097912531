@import '../../../styles/main.scss';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 20px;
  transition: transform 0.3s ease;
  border: 1px solid $color-secondary;
  height: 36px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &.primary {
    background-color: $color-secondary;
    color: $color-white;

    &:active {
      background-color: $color-black;
    }
  }

  &.secondary {
    background-color: transparent;
    color: $color-secondary;

    &:active {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

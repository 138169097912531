@import '../../../styles/main.scss';

.Chevron {
  font-size: $font-size-goldfish;
  background-color: transparent;
  cursor: pointer;
  color: $color-black;

  @include for-desktop-up {
    font-size: $font-size-cat;
  }

  @include for-desktop-large-up {
    font-size: $font-size-coati;
  }

  // Left chevron, Right chevron
  &:first-child,
  &:last-child {
    @include for-mobile-only {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // Left chevron
  &:first-child {
    @include for-mobile-only {
      left: 10px;
      transform: rotate(-90deg);
    }
  }

  // Right chevron
  &:last-child {
    @include for-mobile-only {
      right: 10px;
      transform: rotate(-90deg);
    }
  }
}

@import '../../../styles/main.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: $header-height-standard;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid $color-transparent-10;
  background-color: $color-secondary;
  z-index: 2;

  @include for-mobile-only {
    height: $header-height-mobile;
  }
}

.Menu {
  display: flex;

  &:not(.MenuToggled) {
    @include for-mobile-only {
      display: none;
    }
  }

  &.MenuToggled {
    @include for-mobile-only {
      animation: $swing-in;
      background-color: $color-background;
      height: calc(100% - $header-height-mobile);
      height: calc(100svh - $header-height-mobile);
      width: 100%;
      position: fixed;
      top: $header-height-mobile;
      left: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.MenuWrapper {
  display: flex;

  @include for-mobile-only {
    padding: 48px 0;
    overflow-y: scroll;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

@import '../../../styles/main.scss';

.SkillCategory {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include for-mobile-only {
    align-items: center;
  }
}

.Heading {
  margin-bottom: 30px;
  text-align: center;

  @include for-tablet-portrait-up {
    text-align: left;
  }
}

.content {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 28px;

  @include for-tablet-portrait-up {
    width: 85%;
  }

  @include for-desktop-up {
    width: 75%;
  }

  @include for-mobile-only {
    justify-content: center;
  }
}
